.body {
    padding: 0rem 1rem 0 1rem;
    display: flex;
   
}

.approvalDocuments{

}

.withoutApprovalDocuments{
    color:green
}

.buttons {
    display: flex; justify-content: space-between;
    padding: 0rem;
}

.header {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.heading {
    margin: .5rem .5rem .5rem 0;
}

.overlay {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.wrapper {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}
.large {
    composes: wrapper;
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.small {
    composes: wrapper;
    top: 20%;
    max-height: 60%;
}

.ontop{
    z-index: 200;
    position:absolute;
}
.x {
    cursor: pointer;
    font-size: var(--xxl);
}


.docColumn {
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
    
}

.columnL {
    flex: 50%;
    padding: 1rem;
    padding-bottom: 1rem;
    border-right: 1px solid var(--luight); 
}

.column {
    flex: 50%;
    padding: 1rem;
    text-align: center;
}

.bottombutton{
    margin-left:auto;
    padding: 1rem;
}

.singlebutton{
    padding: 0.3rem;
    width:12rem;
}