.topRow {
    display: flex; justify-content: space-between;
    color: var(--semidark);
    font-size: var(--s);
    margin: 0 0.2rem 0rem 0rem;
    font-weight: bold;
    }

.topbuttons {
     
}

.itemstable {
    background: black; 
    width: 98%;
    line-height: 0;
    margin: 0rem 0.0rem 0.0rem 1%;
    line-height: 1;
    justify-content: auto;
    
}

.headtable {
    background: orange; 
    width: 98%;
    line-height: 0;
    margin: 0.0rem 0.5rem 0.0rem 1%;
    line-height: 1;
    justify-content: auto;
    
}


.itemstableheader{
    margin: 0;
    background : var(--light);
    text-align: center;
}

.itemsth{

    justify-content: center;
    border: 0px solid var(--semilight);
    
}

.itemstr{

    justify-content: center;
    border: 0px solid var(--semilight);
    background: white;
}

.headitemstr{
    
    justify-content: center;
    border: 0px solid var(--semilight);
    background: white;
}

.itemstablebody{

    text-align: center;
    border: 1px solid var(--semilight);
    border-collapse:  collapse;
    font-family: var(--font);
    font-size: var(--s);
    line-height: 1;
}

.headtablebody{
    text-align: center;
    border: 1px solid var(--semilight);
    border-collapse:  collapse;
    font-family: var(--font);
    font-size: var(--s);
    line-height: 1;
}

.itemstd1{

    justify-content: center;
    border: 0px solid var(--semilight);
    padding: 0rem 0rem 0 0rem;
    background: var(--light); /*i pozadi mezer mezi rows*/
}

.headitemstd1{
    justify-content: center;
    border: 0px solid var(--semilight);
    padding: 0rem 0rem 0rem 0rem;
    background: var(--light); /*i pozadi mezer mezi rows*/
    position: sticky;
    top: 0; 
}

.wrapper {
    width: 100%;
    background: var(--light);
    border: 0px solid var(--semilight);
    margin: 0 0 0rem 0;
    max-height: 35vh;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
}

.mainwrapper {
    background: var(--light);
    border: 1px solid var(--semilight);
    margin: 0 0 2rem 0;
    text-align:justify;
    overflow: hidden
}


.items {
    background: var(--light);
    border: var(--border);
    margin-bottom: 1rem;
    padding: .5rem .5rem 0 .5rem;
    
}

.title{
    color: var(--semidark);
    display: block;
    font-size: var(--s);
    font-weight: bold;
    margin: .5rem .5rem 0.5rem .5rem;  
    padding: .3rem .3rem 0.3rem .3rem;  

}


.button {
    margin: .5rem .5rem .5rem .5rem;    
    padding: .3rem .3rem 0.3rem .3rem;
    width : 7rem;
    float:right;
}

