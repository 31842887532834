@import url(https://fonts.googleapis.com/css?family=Roboto:light|Roboto:bold|Roboto:black);
:root {
    --font: Roboto, sans-serif;
    --xs: .75rem;
    --s: .85rem;
    --m: .95rem;
    --l: 1.05rem;
    --xl: 1.15rem;
    --xxl: 1.5rem;
    --primary: rgb(157, 59, 138);
    --primarylight: rgb(235, 195, 227);
    --hover: rgb(147, 49, 128);
    --dark: rgb(121, 121, 131);
    --semidark: rgb(178, 176, 189);
    --semilight: rgb(225, 223, 236);
    --light: rgb(244, 245, 250);
    --purple: rgb(121, 69, 153);
    --border: 1px solid var(--semilight);
    --green: rgb(125, 209, 88);
    --red: rgb(234, 52, 51);
    --orange: rgb(245, 160, 54);
    --radius: 3px;
    --form-max-width: 44rem;
}

a {
    color: rgb(157, 59, 138);
    color: var(--primary);
    text-decoration: none;
}

body {
    font-family: Roboto, sans-serif;
    font-family: var(--font);
    font-size: .95rem;
    font-size: var(--m);
    margin: 0;
}

button {
    font-size: .85rem;
    font-size: var(--s);
    outline: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap;
}

button:hover {
    cursor: pointer;
}

dd {
    display: inline-block;
    margin-left: .25rem;
}

dt {
    display: inline-block;
}

h1 {
    font-size: 1.5rem;
    font-size: var(--xxl);
    font-weight: 300;
    margin: 0 0 1rem 0;
    white-space: nowrap;
}

h2 {
    font-size: 1.05rem;
    font-size: var(--l);
    font-weight: 300;
    margin: 0 0 1rem 0;
    white-space: nowrap;
}

h3 {
    color: rgb(178, 176, 189);
    color: var(--semidark);
    display: block;
    font-size: .95rem;
    font-size: var(--m);
    font-weight: bold;
    margin: 0 0 1.5rem 0;
}

html {
    font-size: calc(9px + .5vw);
}

input {
    background: none;
    border: none;
    border-bottom: 1px solid rgb(225, 223, 236);
    border-bottom: var(--border);
    font-size: .85rem;
    font-size: var(--s);
    line-height: 1.1rem;
    outline: none;
    padding: .5rem 0;
    width: 100%; min-width: 0;
}
:disabled {
    background: transparent;
    color: rgb(121, 121, 131);
    color: var(--dark);
}
[type="checkbox"] {
    -webkit-appearance: none;
    background: white;
    border: 1px solid rgb(225, 223, 236);
    border: var(--border);
    border-radius: 3px;
    border-radius: var(--radius);
    height: 1.25rem; width: 1.25rem;
    padding: 0; margin: 0 0;
}
[type="checkbox"]:checked {
    background: url('data:image/svg+xml;utf8, <svg version="1" fill="rgb(157, 59, 138)" xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 60 60"><path d="M39.7 7.6c0-1.1-.9-2-2-2-.6 0-1.1.2-1.4.6L12.9 29.5l-9.2-9.2c-.4-.4-.9-.6-1.4-.6-1.1 0-2 .9-2 2 0 .6.2 1.1.6 1.4l10.2 10.2c.3.6 1 1.1 1.8 1.1h.2c.8 0 1.4-.4 1.8-1.1L39.1 9c.3-.3.6-.8.6-1.4z"/></svg>') no-repeat center;
    background-color: white;
}
[type="checkbox"]:disabled {
    border-color: rgb(244, 245, 250);
    border-color: var(--light)
}
[type="number"] {
    -moz-appearance: textfield;
    background-color: transparent;
}
[type="password"] {
    color: rgb(157, 59, 138);
    color: var(--primary);
}
[type="number"]:active, [type="number"]:focus, [type="password"]:focus, [type="text"]:focus, select:focus {
    border-bottom: 1px solid rgb(178, 176, 189);
    border-bottom: 1px solid var(--semidark);
}
[type="radio"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 1px solid rgb(225, 223, 236);
    border: var(--border);
    border-radius: 50%;
    box-shadow: inset 0 0 0 .25rem white;
    height: 1.25rem;
    margin: 0 .25rem;
    width: 1.25rem;
}
[type="radio"]:checked {
    background: rgb(157, 59, 138);
    background: var(--primary);
}
[type="radio"]:checked:disabled {
    background-color: rgb(121, 121, 131);
    background-color: var(--dark);
    opacity: .7;
}
[type="radio"]:focus {
    border-color: rgb(178, 176, 189);
    border-color: var(--semidark);
}
::-webkit-input-placeholder {
    color: rgb(121, 121, 131);
    color: var(--dark);
    font-family: Roboto, sans-serif;
    font-size: .75rem;
    font-size: var(--xs);
    font-weight: 300;
}
:-ms-input-placeholder {
    color: rgb(121, 121, 131);
    color: var(--dark);
    font-family: Roboto, sans-serif;
    font-size: .75rem;
    font-size: var(--xs);
    font-weight: 300;
}
::placeholder {
    color: rgb(121, 121, 131);
    color: var(--dark);
    font-family: Roboto, sans-serif;
    font-size: .75rem;
    font-size: var(--xs);
    font-weight: 300;
}
::-webkit-inner-spin-button {
    display: none;
}

label {
    color: rgb(178, 176, 189);
    color: var(--semidark);
    display: block;
    font-size: .85rem;
    font-size: var(--s);
    font-weight: bold;
}

p {
    margin: 0;
}

progress {
    appearance: none; -moz-appearance: none; -webkit-appearance: none;
    background-color: rgb(244, 245, 250);
    background-color: var(--light);
    border: none;
    color: rgb(157, 59, 138);
    color: var(--primary);
    display: block;
    height: .25rem; width: 100%;
}
progress::-moz-progress-bar {
    background-color: rgb(157, 59, 138);
    background-color: var(--primary);
}
progress::-webkit-progress-bar {
    background-color: rgb(244, 245, 250);
    background-color: var(--light);
}
progress::-webkit-progress-value {
    background-color: rgb(157, 59, 138);
    background-color: var(--primary);
}

select {
    -webkit-appearance: none;
    background: url('data:image/svg+xml;utf8, <svg fill="rgb(157, 59, 138)" version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -18 15 42"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.8 3.4L8.2 1.8 5 5.1 1.7 1.8.2 3.3l4.5 4.5.4.4z"/></svg>') no-repeat right;
    border: none;
    border-bottom: 1px solid rgb(225, 223, 236);
    border-bottom: var(--border);
    border-radius: 0;
    font-size: .85rem;
    font-size: var(--s);
    line-height: 1.1rem;
    outline: none;
    text-overflow: ellipsis;
    padding: .5rem .85rem .5rem 0;
    width: 100%;
}
select:disabled {
    background: url('data:image/svg+xml;utf8, <svg fill="rgb(225, 223, 236)" version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -18 15 42"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.8 3.4L8.2 1.8 5 5.1 1.7 1.8.2 3.3l4.5 4.5.4.4z"/></svg>') no-repeat right;
    color: rgb(121, 121, 131);
    color: var(--dark);
}

table {
    display: grid;
    margin-bottom: .5rem;
}
tbody, thead {
    display: contents;
}
td {
    border-bottom: 1px solid rgb(225, 223, 236);
    border-bottom: var(--border);
    padding: .25rem;
}
th {
    border-bottom: 1px solid rgb(225, 223, 236);
    border-bottom: 1px solid var(--semilight);
    color: rgb(178, 176, 189);
    color: var(--semidark);
    display: flex; align-items: center;
    justify-content: flex-end;
    font-size: .75rem;
    font-size: var(--xs);
    /*height: var(--xs);*/
    padding: .5rem .25rem;
}
tr {
    display: contents;
}

textarea {
    border: 1px solid rgb(225, 223, 236);
    border: var(--border);
    border-radius: 3px;
    border-radius: var(--radius);
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    font-family: var(--font);
    font-size: .85rem;
    font-size: var(--s);
    margin-top: .5rem;
    outline: none;
    padding: .25rem;
    width: 100%;
}

ul {
    padding-left: 0;
}
.Button_button__cnDxQ {
    border: 1px solid;
    border-color: var(--primary);
    border-radius: var(--radius);
    padding: .5rem 1.5rem;
}
.Button_primary__3JPxY {
    background-color: var(--primary);
    color: white;
}
.Button_primary__3JPxY:disabled {
    background-color: var(--light);
    border-color: var(--semidark);
    cursor: default;
    color: var(--primary);
}

.Button_primary__3JPxY:hover:not(:disabled) {
    background-color: var(--hover);
}

.Button_wideprimary__4qelK {
    background-color: var(--primary);
    color: white;
    width:100%;
}
.Button_wideprimary__4qelK:disabled {
    background-color: var(--light);
    border-color: var(--semidark);
    cursor: default;
    color: var(--primary);
}

.Button_wideprimary__4qelK:hover:not(:disabled) {
    background-color: var(--hover);
}


.Button_secondary__1IiS8 {
    background: white;
    color: var(--primary);
}

.Button_secondary__1IiS8:disabled {
    border-color: var(--semidark);
    color: var(--semidark);
    cursor: default;
}

.Button_secondary__1IiS8:hover:not(:disabled) {
    color: var(--hover);
}

.AuthForm_form__3CZBQ {
    display: flex; flex-direction: column;
}

.AuthForm_heading__2vc-P {
    margin-top: 20vh;
}

.AuthForm_info__2gRA3 {
    color: var(--dark);
    margin-bottom: 1rem;
}

.AuthForm_link__38OPu {
    display: block;
    margin-top: .5rem;
}
.Input_asterisk__3jOqx {
    content: '*';
    color: var(--red);
    font-size: var(--xs);
    margin-left: .25rem;
}

.Input_error__Rq_gu {
    color: var(--primary);
    font-size: .75rem;
    font-weight: 300;
}

.Input_errorPlaceholder__KR9Mo {
    margin-top: .25rem;
    min-height: 1rem;
}

.Input_hidden__1PclF {
    visibility: hidden;
}
.Welcome_box__2_dOS {
    display: flex;
    justify-content: center;
}
.Welcome_leftBox__26p6O {
    flex: 45 1;
}
.Welcome_rightBox__1TqSn {
    background-image: url(/static/media/welcome.ebab9aa1.jpg);
    background-size: cover;
    background-position: right;
    box-shadow: inset 0 0 0 100vh rgba(35, 37, 66, 0.75);
    flex: 55 1;
}

.Welcome_container__2qOXs {
    display: flex; flex-wrap: wrap;
    min-height: 100vh;
}

.Welcome_form__ujDhW {
    display: flex; flex-direction: column;
    margin: 1rem;
    width: 14rem;
}

.Welcome_logo__25QKT {
    margin-top: 20vh;
    max-width: 2rem;
}

.Welcome_welcome__ejW7r {
    margin: 0 32% 0 10%;
    padding-left: 3rem;
}

.Welcome_welcomeHeading__3_ecx {
    color: white;
    line-height: 2.5rem;
    text-transform: uppercase;
}
.Welcome_welcomeHeadingBottom__rMmhO {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 1.75rem;
}
.Welcome_welcomeHeadingTop__3LXsX {
    font-size: 2.75rem;
    margin: 3rem 0 0 0;
}

.Welcome_welcomeText__2Me0D {
    color: var(--semilight)
}

.Toast_container__IqTi6 {
    background: white;
    border: 1px solid var(--primary);
    border-radius: var(--radius);
    color: var(--primary);
    position: fixed;
    /*top: 2rem;*/
    font-size: calc(var(--m) + 3px);
    top: 50%;
    left: 50%;
    margin-right: -50%;
    margin-bottom: -50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    z-index: 11111;
}

.Toast_hidden__GO_FV {
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease-out;
}

.Toast_visible__1fjI2 {
    transition: opacity .5s ease-in;
}

.Toast_list__Pivf0 {
    color: var(--dark);
    font-weight: bold;
    margin: .5rem 0 0 .5rem;
    line-height: 1.5rem;
    list-style: none;
}
.Modal_body__2qW6r {
    padding: 1rem 1rem 0 1rem;
    min-width: 200px;
}

.Modal_buttons__mwN_W {
    display: flex; justify-content: space-between;
    min-width: 12rem;
    padding: 1rem;
}

.Modal_buttonsCapture__2gMFK {
    display: flex; justify-content: space-between;
    min-width: 12rem;
    padding: 1rem;
}

.Modal_buttonsingle__31iCd {
    text-align: right;
    padding: 1rem;
}

.Modal_header__2Abub {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.Modal_heading__7XmVl {
    margin: .5rem .5rem .5rem 0;
}

.Modal_infotablebody__Vhk6X{
    grid-template-columns: 50% 50%;
    width: 500px;
    text-align: left;
}

.Modal_infohead__hCDLT{
    font-weight: bold;
    color: var(--dark)
}

.Modal_overlay__1E-V2 {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.Modal_wrapper__NxzrG {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 22rem;*/
    position: absolute;
    z-index: 50;
}

.Modal_ontop__1lsOB{
    z-index: 400;
    position:absolute;
}
.Modal_large__2Vc68 {
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.Modal_small__3ArTs {
    top: 10%;
    max-height: 90%;
}
.Modal_itemsEdit__2jIIt {
    top: 10rem;
    left: 10rem;
}

.Modal_x__OCiFQ {
    cursor: pointer;
    font-size: var(--xxl);
}

.Modal_scroll__3uPSN {
    overflow-y: auto;
}

.Modal_columnDiv__3PmYe {
    display: flex;
    align-content: stretch;
    padding: 10px;
}

.Modal_columnDiv__3PmYe select {
    margin-right: 10px;
}

.Modal_columnDiv__3PmYe button {
    flex-grow: 1;
}

.Modal_columnDiv__3PmYe button + button {
    margin-left: 10px;
}

.Modal_buttonleft__SZJzI {
    margin: 0.3rem;
    margin-top: 0;
    height: 2em;
    padding: 0 0.5em; /* Adjust padding as needed */
    width: auto; /* Width adjusts to fit text */
    display: inline-block; /* Makes the button width fit its content */
    text-align: center; /* Centers text inside the button */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.Modal_buttonright__1WDbP {
    margin: 0.3rem;
    margin-top: 0;
    height: 2em;
    padding: 0 0.5em; /* Adjust padding as needed */
    width: auto; /* Width adjusts to fit text */
    display: inline-block; /* Makes the button width fit its content */
    text-align: center; /* Centers text inside the button */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}
.ForwardPickerModal_body__3eTxs {
    padding: 0rem 1rem 0 1rem;
    display: flex;
   
}

.ForwardPickerModal_approvalDocuments__2Ocxp{

}

.ForwardPickerModal_withoutApprovalDocuments__3LZb-{
    color:green
}

.ForwardPickerModal_buttons__3Iqml {
    display: flex; justify-content: space-between;
    padding: 0rem;
}

.ForwardPickerModal_header__5xeOs {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.ForwardPickerModal_heading__1_MPH {
    margin: .5rem .5rem .5rem 0;
}

.ForwardPickerModal_ontop__2FJP1{
    z-index: 200;
    position:absolute;
}

.ForwardPickerModal_overlay__2w6Aq {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.ForwardPickerModal_wrapper__2IKB6 {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}
.ForwardPickerModal_large__2hGYY {
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.ForwardPickerModal_small__3RCMd {
    top: 20%;
    max-height: 60%;
}

.ForwardPickerModal_x__1Iimf {
    cursor: pointer;
    font-size: var(--xxl);
}


.ForwardPickerModal_docColumn__WVTj_ {
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
    overflow-y: auto;
    
}

.ForwardPickerModal_columnL__owOAX {
    flex: 50% 1;
    padding: 1rem;
    padding-bottom: 0rem;
    border-right: 1px solid var(--luight); 
}

.ForwardPickerModal_column__3zPkF {
    flex: 50% 1;
    padding: 1rem;
    padding-bottom: 0rem;
    text-align: center;
}

.ForwardPickerModal_bottombutton__2rkM_{
    margin-left:auto;
    padding: 1rem;
}

.ForwardPickerModal_twinbutton__6lMxP{
    margin-left:auto;
    padding-top: 1rem;
    
}

.ForwardPickerModal_directbutton__2ebu0{
    margin-left:auto;
    padding-bottom: 1rem;
}

.ForwardTreePickerModal_body__2-5RO {
    padding: 0rem 1rem 0 1rem;
    display: flex;
   
}

.ForwardTreePickerModal_approvalDocuments__1Qwr_{

}

.ForwardTreePickerModal_withoutApprovalDocuments__3zvBk{
    color:green
}

.ForwardTreePickerModal_buttons__33bhE {
    display: flex; justify-content: space-between;
    padding: 0rem;
}

.ForwardTreePickerModal_header__1xE_r {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.ForwardTreePickerModal_heading__CkEOL {
    margin: .5rem .5rem .5rem 0;
}

.ForwardTreePickerModal_overlay__2Kksr {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.ForwardTreePickerModal_wrapper__3hygI {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}
.ForwardTreePickerModal_large__3doOE {
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.ForwardTreePickerModal_small__3Dyuk {
    top: 20%;
    max-height: 60%;
}

.ForwardTreePickerModal_x__XcySO {
    cursor: pointer;
    font-size: var(--xxl);
}
.ForwardTreePickerModal_ontop__1JZBq{
    z-index: 200;
    position:absolute;
}


.ForwardTreePickerModal_docColumn__1nJat {
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
    overflow-y: auto;
    overflow-x: auto;
    max-height: 10vh;
    max-width: 600px;
}

.ForwardTreePickerModal_columnL__3UC3p {
    text-align: left;
}

.ForwardTreePickerModal_column__29_0d {
    text-align:  left;
    flex: 50% 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.ForwardTreePickerModal_columnManagers__1ip-w{
    overflow-y: auto;   
    max-height: 48vh;
}

.ForwardTreePickerModal_bottommessage__1yx6G {
    color: red;
    text-align:  center;
    flex: 50% 1;
    padding-top: 0rem;
    padding-bottom: 1rem;
    text-align: center;
}

.ForwardTreePickerModal_bottombutton__38fm1{
    margin-left:auto;
    padding: 1rem;
}

.DuplicateTableRow_padding__213Y5 {
    padding-right: 1rem;
}

.DuplicateTableRow_uuid__9O6yt {
    font-weight: bold;
}
.DuplicateModal_body__2g7I- {
    margin: 1rem 1rem 0 1rem;
    overflow: auto;
}

.DuplicateModal_buttons__xl22W {
    display: flex; justify-content: space-between;
    margin: 1rem;
}

.DuplicateModal_table__2lIpK {
    grid-template-columns: auto auto auto;
    margin: .5rem;
}

.DuplicateModal_checkbox__1MwV_ {
    float: right;
    margin-right: 16px;
    border-width: 2px;
    /*position: absolute;*/
    display: inline-block;
}

.DuplicateModal_p-short__Fus5_ {

    display: inline-block;
    max-width: 350px;
}

.DuplicateModal_block__1g0tJ{
    margin-bottom: 16px;
    /*filter: brightness(96%);*/
}

.DuplicateModal_ontop__2n6aP{
    z-index: 300;
    position:absolute;
}
.FieldsWrapper_columns__21BGP {
    display: grid; grid-gap: .25rem 2rem; gap: .25rem 2rem;
}

.FieldsWrapper_two__1DNQp {
}
@media (min-width: 45rem) {
    .FieldsWrapper_two__1DNQp {
        grid-template-columns: repeat(2, 1fr);
    }
}

.FieldsWrapper_three__3Aotm {
}
@media (min-width: 768px) {
    .FieldsWrapper_three__3Aotm {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .FieldsWrapper_three__3Aotm {
        grid-template-columns: repeat(2, 1fr);
    }
}

.FieldsWrapper_four__gv3AB {
}
@media (min-width: 768px) {
    .FieldsWrapper_four__gv3AB {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .FieldsWrapper_four__gv3AB {
        grid-template-columns: repeat(2, 1fr);
    }
}

.Radio_label__32t9M {
    align-items: center;
    color: var(--dark);
    display: flex;
    flex-direction: row-reverse;
    font-weight: normal;
    margin-right: 1rem;
}
.YesNo_heading__2amWO {
    color: var(--semidark);
    font-size: var(--s);
    font-weight: bold;
    margin-bottom: .5rem;
}

.YesNo_inputs__2P8zI {
    display: flex;
    margin-bottom: 1rem;
}
.Cost_buttons__3pV73 {
    display: flex;
    padding-top: 1rem;
}
.Cost_multipleButtons__WDHlq {
    justify-content: space-between;
}
.Cost_singleButton__17gUg {
    justify-content: flex-end;
}

.Cost_cancelIcon__1H8n3 {
    cursor: pointer;
    fill: var(--primary);
    height: 1rem;
    width: var(--xs);
}

.Cost_topRow__2ALeu {
    display: flex; justify-content: flex-end;
}

.Cost_wrapper__1kkQs {
    background: var(--light);
    border: var(--border);
    margin-bottom: 1rem;
    padding: .5rem .5rem 0 .5rem;
}
.Costs_button__1tZK9 {
    align-self: center;
    justify-self: right;
}

.Costs_container__4zUcd {
    display: flex; flex-direction: column;
}

.Costs_total__28R7t {
    color: var(--dark);
    font-weight: 700;
    grid-column-start: 2;
    justify-self: right;
    margin: 0 0 1rem 0;
}

.Costs_rounding__2wOiY {
    flex-direction: column;
    color: var(--dark);
    font-weight: 700;
    grid-column-start: 4;
    justify-self: right;
    margin: 0 0 1rem 0;
}

.Items_topRow__MZVxK {
    display: flex; justify-content: space-between;
    color: var(--semidark);
    font-size: var(--s);
    margin: 0 0.2rem 0rem 0rem;
    font-weight: bold;
    }

.Items_topbuttons__M9kj0 {
     
}

.Items_itemstable__1a9_D {
    background: black; 
    width: 98%;
    line-height: 0;
    margin: 0rem 0.0rem 0.0rem 1%;
    line-height: 1;
    justify-content: auto;
    
}

.Items_headtable__3izev {
    background: orange; 
    width: 98%;
    line-height: 0;
    margin: 0.0rem 0.5rem 0.0rem 1%;
    line-height: 1;
    justify-content: auto;
    
}


.Items_itemstableheader__2MWBe{
    margin: 0;
    background : var(--light);
    text-align: center;
}

.Items_itemsth__5kBN2{

    justify-content: center;
    border: 0px solid var(--semilight);
    
}

.Items_itemstr__1zNYT{

    justify-content: center;
    border: 0px solid var(--semilight);
    background: white;
}

.Items_headitemstr__3Cj2q{
    
    justify-content: center;
    border: 0px solid var(--semilight);
    background: white;
}

.Items_itemstablebody__2bqmb{

    text-align: center;
    border: 1px solid var(--semilight);
    border-collapse:  collapse;
    font-family: var(--font);
    font-size: var(--s);
    line-height: 1;
}

.Items_headtablebody__XDZ2L{
    text-align: center;
    border: 1px solid var(--semilight);
    border-collapse:  collapse;
    font-family: var(--font);
    font-size: var(--s);
    line-height: 1;
}

.Items_itemstd1__38gJn{

    justify-content: center;
    border: 0px solid var(--semilight);
    padding: 0rem 0rem 0 0rem;
    background: var(--light); /*i pozadi mezer mezi rows*/
}

.Items_headitemstd1__1JR44{
    justify-content: center;
    border: 0px solid var(--semilight);
    padding: 0rem 0rem 0rem 0rem;
    background: var(--light); /*i pozadi mezer mezi rows*/
    position: -webkit-sticky;
    position: sticky;
    top: 0; 
}

.Items_wrapper__3fAeo {
    width: 100%;
    background: var(--light);
    border: 0px solid var(--semilight);
    margin: 0 0 0rem 0;
    max-height: 35vh;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
}

.Items_mainwrapper__3h3c7 {
    background: var(--light);
    border: 1px solid var(--semilight);
    margin: 0 0 2rem 0;
    text-align:justify;
    overflow: hidden
}


.Items_items__4dKUI {
    background: var(--light);
    border: var(--border);
    margin-bottom: 1rem;
    padding: .5rem .5rem 0 .5rem;
    
}

.Items_title__30Fv2{
    color: var(--semidark);
    display: block;
    font-size: var(--s);
    font-weight: bold;
    margin: .5rem .5rem 0.5rem .5rem;  
    padding: .3rem .3rem 0.3rem .3rem;  

}


.Items_button__Mksez {
    margin: .5rem .5rem .5rem .5rem;    
    padding: .3rem .3rem 0.3rem .3rem;
    width : 7rem;
    float:right;
}


.ItemRow_topRow__GsKSy {
    display: flex; justify-content: flex-start;
    color: var(--semidark);
    font-size: var(--s);
    margin: 0 0 0.2rem 0;
    font-weight: bold;
}
.ItemRow_cancelIcon__32aQu {
    cursor: pointer;
    fill: var(--primary);
    height: 0.55rem;
    width: var(--xs);
}

.ItemRow_itemstable__1xcQ0 {
    background-color: white;
    border-collapse: collapse;
    border: 1px solid var(--semilight);
    grid-template-columns: 2rem 1fr 0.38fr 0.58fr 0.22fr 0.4fr 0.32fr 0.32fr 0.43fr 0.43fr;
    text-align: center;
    justify-content: auto;
    line-height: 0.6;
    margin: 0 0 0.3rem 0;
    width:100%
   }

.ItemRow_nestedcell1__2Y-wd{
    border: 0px;
    line-height: 0.5rem;
    width:5rem;
    border-right: 2px solid var(--semilight);
    text-align:center;
    margin:0;
}
.ItemRow_nestedcell2__KRhJ8{
    border: 0px;
    line-height: 0.6;
    width:15rem;
    margin:0;
}


.ItemRow_acrosstwocolumns__1kmtZ{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--semilight);
    padding:0;   
    margin:0;
}

.ItemRow_acrosstwocolumnsbottom__3lKsZ{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--semilight);
    
}



.ItemRow_acrosstwocolumnsbottom1__39jxO{
    grid-column: span 2; 
    height: 1.1rem; /* Or set this to `auto` if you want it to inherit from the parent */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--semilight);
    background-color: white;
    box-sizing: border-box;
    overflow: hidden; /* Prevent content overflow */
    flex: 1 1; /* Allow the element to grow to fit its parent */
    }

.ItemRow_itemstd__1luAv{
    height:0.5rem;
    justify-content: center;
    border: 0.5px solid var(--semilight);
    background-color: white;
    
}

.ItemRow_itemstd1__1-WeD {
    height: 1.1rem; /* Or set this to `auto` if you want it to inherit from the parent */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--semilight);
    background-color: white;
    box-sizing: border-box;
    overflow: hidden; /* Prevent content overflow */
    flex: 1 1; /* Allow the element to grow to fit its parent */
}


.ItemRow_checkcell__14Cj-{
    height:0.5rem;
    border: 0.5px solid var(--semidark);
    background-color: white;
    align-items: center;       /* Center content vertically */
    justify-content: center;   /* Center content horizontally */
}

.ItemRow_acrosstwocolumnshighlighted__2hH2W{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--dark);
}


.ItemRow_acrosstworows__2YOOA{
    grid-row: span 2; 
    justify-content: center;
    border: 1px solid var(--semilight);
}

.ItemRow_itemstableheader__2yNwv{
    background-color: var(--light);
    text-align: center;
    border: 1px solid var(--semilight);
    
}

.ItemRow_itemstablebody__1AEdo{
    background : var(--light);
    text-align: center;
    border-collapse:  collapse;
    
}

.ItemRow_rowwrapper__P4j3F {
    display: flex;
    width:100%;
    flex-direction: row; 
    grid-gap: 5px; 
    gap: 5px; 
}

.ItemRow_fixedsizecheckbox__hjuW6 {
    height: 0.2rem,  !important;
}
.ItemCell_asterisk__2eGfD {
    content: '*';
    color: var(--red);
    font-size: 0.5rem;
    margin-left: .25rem;
}

.ItemCell_error__x-5a3 {
    color: var(--primary);
    font-size: .75rem;
    font-weight: 300;
}

.ItemCell_errorPlaceholder__8QUI4 {
    margin-top: .25rem;
    min-height: 1rem;
}

.ItemCell_hidden__1jp7O {
    visibility: hidden;
}

.ItemCell_itemcell__31iXl{
    color: var(--dark);
    font-size: var(--xs);
    padding: 0px;
    margin:0px;
}

.ItemCell_formikcell__3EiOQ{
    border: 0px solid white;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 0px;
    font-size: var(--xs);
    border-bottom: 0px solid var(--semilight);
    outline: none;
}


.ItemCell_formikcheckbox__3-Uyg{
    border: 0px solid white;
    height: 0.8rem;
    width: 1.6rem;
    vertical-align: middle;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 0px;
    font-size: var(--xs);
    border-bottom: 0px solid var(--semilight);
    outline:  1px solid var(--semilight);;
    
    
}
.ItemsHeader_topRow__3eqYZ {
    display: flex; justify-content: flex-start;
    color: var(--semidark);
    font-size: var(--s);
    margin: 0 0 0.0rem 0;
    font-weight: bold;
}
.ItemsHeader_cancelIcon__3z-gd {
    cursor: pointer;
    fill: var(--primary);
    height: 1rem;
    width: var(--xs);
}

.ItemsHeader_itemstable__299xF {
    width:100%;
    background-color: white;
    border-collapse: collapse;
    border: 1px solid var(--semilight);
    grid-template-columns: 2rem 1fr 0.38fr 0.58fr 0.22fr 0.4fr 0.32fr 0.32fr 0.43fr 0.43fr;
    text-align: center;
    justify-content: auto;
    line-height: 0.6;
    margin: 0 0 0.3rem 0;
   }

.ItemsHeader_acrosstwocolumns__33Yvv{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--semilight);
}

.ItemsHeader_polozka__XR3wh{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--semilight);
    padding:0;    
}

.ItemsHeader_acrosstworows__1VmJ9{
    grid-row: span 2; 
    justify-content: center;
    border: 0.5px solid var(--semilight);
}

.ItemsHeader_itemstableheader__16X5L{
    background-color: var(--light);
    text-align: center;
    border: 0.5px solid var(--semidark);
    
}

.ItemsHeader_itemstablebody__38qbG{
    background : var(--light);
    text-align: center;
    border-collapse:  collapse;
    
}

.ItemsHeader_itemstd__3vWYQ{
    justify-content: center;
    border: 0.5px solid var(--semilight);
    background-color: white;
    white-space: nowrap;
}

.ItemsHeader_nestedcell1__22Ebr{
    border: 0px;
    line-height: 0.6;
    width: 5rem;
    border-right: 2px solid var(--semilight);
    text-align:center;
}
.ItemsHeader_nestedcell2__3yFuo{
    border: 0px;
    line-height: 0.6;
    width:15rem;
    text-align:center;
}


.ItemsHeader_checkcell__sk2Oa{
    height:0.5rem;
    border: 0.5px solid var(--semidark);
    background-color: white;
    align-items: center;       /* Center content vertically */
    justify-content: center;   /* Center content horizontally */
}


.ItemsHeader_rowwrapper__3NbSB {
    width:100%;
    display: flex;
    flex-direction: row; 
    grid-gap: 5px; 
    gap: 5px; 
}

.ItemsHeader_fixedsizecheckbox__3ufkP {
    height: 0.2rem,  !important;
}
.DatePicker_calendar__2lEtC {
    border-color: var(--semilight);
    border-radius: var(--radius);
    font-family: var(--font);
    font-size: var(--xs);
    font-weight: 300;
}

.DatePicker_calendar__2lEtC > button:nth-child(2) {
    border-right-color: var(--semidark);
}

.DatePicker_calendar__2lEtC > button:nth-child(2):hover {
    border-right-color: var(--dark);
}

.DatePicker_calendar__2lEtC > button:nth-child(3) {
    border-left-color: var(--semidark);
}

.DatePicker_calendar__2lEtC > button:nth-child(3):hover {
    border-left-color: var(--dark);
}

.DatePicker_calendar__2lEtC > div:first-child {
    border-bottom-color: var(--light) !important;
}

.DatePicker_calendar__2lEtC > div:first-child::before {
    border-bottom-color: var(--semilight) !important;
}

.DatePicker_calendar__2lEtC > div:last-child > div:first-child {
    background-color: var(--light);
    border-color: var(--semilight);
}

.DatePicker_container__3CTQL > div:first-child, .DatePicker_container__3CTQL > div:first-child > div:first-child {
    display: block;
}

.DatePicker_input__1LeXd {
    background: url('data:image/svg+xml;utf8, <svg version="1" fill="rgb(157, 59, 138)" xmlns="http://www.w3.org/2000/svg" viewBox="-48 -30 90 90"><path d="M35.8 3.7h-3.1V1.5c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v2.2H9.9V1.5C9.9.8 9.3.2 8.6.2S7.2.8 7.2 1.5v2.2h-3c-2.2 0-4 1.8-4 4v28.2c0 2.2 1.8 4 4 4h31.7c2.2 0 4-1.8 4-4V7.7c-.1-2.2-1.9-4-4.1-4zm0 33.5H4.2c-.7 0-1.3-.6-1.3-1.3V15.2h34.3v20.7c0 .7-.6 1.3-1.4 1.3zM4.2 6.4h3.1v2.2c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3V6.4h20.2v2.2c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3V6.4h3.1c.7 0 1.3.6 1.3 1.3v4.8H2.8V7.7c0-.8.6-1.3 1.4-1.3z"/></svg>') no-repeat right;
}

.DatePicker_input__1LeXd:disabled {
    background: url('data:image/svg+xml;utf8, <svg version="1" fill="rgb(225, 223, 236)" xmlns="http://www.w3.org/2000/svg" viewBox="-48 -30 90 90"><path d="M35.8 3.7h-3.1V1.5c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v2.2H9.9V1.5C9.9.8 9.3.2 8.6.2S7.2.8 7.2 1.5v2.2h-3c-2.2 0-4 1.8-4 4v28.2c0 2.2 1.8 4 4 4h31.7c2.2 0 4-1.8 4-4V7.7c-.1-2.2-1.9-4-4.1-4zm0 33.5H4.2c-.7 0-1.3-.6-1.3-1.3V15.2h34.3v20.7c0 .7-.6 1.3-1.4 1.3zM4.2 6.4h3.1v2.2c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3V6.4h20.2v2.2c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3V6.4h3.1c.7 0 1.3.6 1.3 1.3v4.8H2.8V7.7c0-.8.6-1.3 1.4-1.3z"/></svg>') no-repeat right;
}


.react-datepicker__day {
    border-radius: var(--radius) !important;
}

.react-datepicker__day:not(.react-datepicker__day--selected):hover {
    background-color: var(--light);
}

.react-datepicker__day--selected {
    background-color: var(--primary) !important;
}

.HistoryDetail_row__3J4BF {
    background: var(--light);
    border-left: var(--border); border-right: var(--border);
    display: grid;
    grid-column: 1 / 6;
    padding: 0 .5rem;
}
.HistoryDetail_header__3tyPw {
    color: var(--primary);
    font-weight: 700;
    padding-top: .25rem;
}
.HistoryDetail_bottom__3RnbF {
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.HistoryDetail_changeHeader__10cmr {
    grid-template-columns: 10rem 1fr 1fr;
}
.HistoryDetail_changeRow__3c8rx {
    grid-template-columns: 10rem 1fr 1fr;
}
.HistoryDetail_changeBottom__3Wqmh {
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.HistoryDetail_costsHeader__2w7TR {
    grid-template-columns: 1.5rem 1fr 1fr 1fr 1fr;
}
.HistoryDetail_costsHeaderShort__29A2f {
    grid-template-columns: 1.5rem 1fr 1fr 1fr;
}
.HistoryDetail_costsRow__Cbw1K {
    grid-template-columns: 1.5rem 1fr 1fr 1fr 1fr ;
}
.HistoryDetail_costsRowShort__fWLld {
    grid-template-columns: 1.5rem 1fr 1fr 1fr ;
}
.HistoryDetail_costsBottom__1jSHF {
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.HistoryDetail_costsBottomShort__3eku8 {
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.HistoryDetail_row__3J4BF > td {
    border: none;
}

.HistoryDetail_greyText__1Z2c0 {
    color: var(--dark);
}

.HistoryDetail_arrowIcon__2kGV2 {
    fill: var(--dark);
    transform: rotate(90deg);
}

.HistoryDetail_costValue__3a9Bt {
    color: var(--dark);
    text-align: center;
}
.HistoryDetail_costValueChanged__19MUJ {
    color: var(--dark);
    display: grid;
    grid-template-columns: 1fr 1rem 1fr;
    justify-items: center;
}

.HistoryTableRow_expandableRow__1HteI {
    cursor: pointer;
}

.HistoryTableRow_expandableHighlightedRow__1ZCmG {
    cursor: pointer;
    font-weight: 700;
}

.HistoryTableRow_greyText__1AwLk {
    color: var(--dark);
}

.HistoryTableRow_highlightedRow__XTHaZ {
    font-weight: 700;
}

.HistoryTableRow_iconCell__PWW-d {
    display: flex; align-items: center;
}

.HistoryTableRow_toggleDetailIcon__2GOO5 {
    cursor: pointer;
    fill: var(--primary);
    width: var(--s);
}
.HistoryTableRow_toggleDetailIcon__2GOO5:hover {
    fill: var(--hover);
}

.HistoryTableRow_icon__3XZ64 {
    width: var(--l);
}
.HistoryTableRow_defaultIcon__39G52 {
    fill: var(--semidark);
}
.HistoryTableRow_importedIcon__15aJ1 {
    fill: var(--green);
}
.HistoryTableRow_removedIcon___nrTq {
    fill: var(--red);
}
.HistoryTableRow_warningIcon__12mIr {
    fill: var(--orange);
}
.HistoryTable_table__3GcL5 {
    grid-template-columns: 1.5rem 8.5rem 7.5rem 1fr 1.5rem;
}
.MetadataRow_property__A9aHl {
    color: var(--semidark);
    font-size: var(--m);
    font-weight: bold;
    min-width: 12rem;
}

.MetadataRow_value__2TcDV {
    font-size: var(--m);
}

.MetadataRow_wrapper__1bed2 {
    border-bottom: var(--border);
    display: flex;
    margin-bottom: .5rem;
    padding: .25rem;
}
.Autocomplete_container__mpc_N {
    position: relative;
}

.Autocomplete_list__Dvjd7 {
    background: white;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}
.Autocomplete_hiddenList__3zYX_ {
    border: none;
}
.Autocomplete_visibleList__3IENe {
    border: var(--border);
    border-top: none;
    max-height: 500px;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    white-space: nowrap;     /* Prevents text from wrapping to a new line */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Autocomplete_item__3N_y0 {
    color: var(--dark);
    font-size: var(--s);
    font-weight: 400;
    padding: .5rem;
}

.Autocomplete_item__3N_y0:hover {
    background-color: var(--light);
    color: black;
}

.Autocomplete_highlight__9oT9l {
    background-color: var(--light);
    color: black !important;
}
.EditForm_buttons__2KuIh {
    display: flex; justify-content: space-between;
    margin-bottom: 1rem;
}

.EditForm_cancelButton__1Xi3y {
    justify-self: start;
}

.EditForm_singleButton__3Sx4v {
    display: flex; justify-content: flex-end;
}
.File_placeholderText__ld2lt {
    color: var(--purple);
    font-size: 1rem;
    font-weight: 700;
    margin-top: 10rem;
    opacity: .4;
    text-align: center;
}
.File_textAnnotation__ZUKIr img{
    display: none;
}
.FilePreview_chevronIcon__1ep2h {
    width: var(--s);
}
.FilePreview_chevronIconInactive__1b-nu {
    fill: var(--semilight);
}
.FilePreview_chevronIconActive__pAc4y {
    cursor: pointer;
    fill: var(--semidark);
}
.FilePreview_chevronIconActive__pAc4y:hover {
    fill: var(--dark);
}

.FilePreview_container__3-QAf {
    border: var(--border);
    
}

.FilePreview_icons__ORy34 {
    align-items:center;
    display: flex;
    justify-content: space-around;
    width: 35%;
}


.FilePreview_options__RCLDE {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--light);
    padding: .25rem .5rem;
}

.FilePreview_optionsRow__BYFjT{
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
}

.FilePreview_page__3jlAo {
    display: table; /* enables selecting text when the document is zoomed in */
}

.FilePreview_pagesContainer__itxmF {
    display: flex; align-items: center;
    justify-self: start;
}

.FilePreview_pagesText__2GuFm {
    font-size: var(--s);
    margin: 0 .25rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.FilePreview_placeholder__LWI9y {
    display: flex; flex-direction: column;
    overflow: auto;
}

.FilePreview_placeholderText__1Q0I_ {
    color: var(--purple);
    font-size: 1rem;
    font-weight: 700;
    margin: 125px 0 350px 0;
    opacity: .4;
}

.FilePreview_select__1P8v7 {
    background-size: .85rem !important;
    flex-grow: 3;
    padding: .25rem .5rem .25rem .25rem;
}

.FilePreview_removeIcon__9Q6-y {
    margin-right: .25rem;
    width: var(--m);
}
.FilePreview_removeIconInactive__D26a5 {
    fill: var(--semilight);
}
.FilePreview_removeIconActive__dmkaR {
    cursor: pointer;
    fill: var(--semidark);
}
.FilePreview_removeIconActive__dmkaR:hover {
    fill: var(--dark);
}

.FilePreview_uploadIcon__3GEmM {
    justify-self: end;
    width: var(--xxl);
}
.FilePreview_uploadIconInactive__S7xbJ {
    fill: var(--semilight);
}
.FilePreview_uploadIconActive__epqwx {
    cursor: pointer;
    fill: var(--semidark);
}
.FilePreview_uploadIconActive__epqwx:hover {
    fill: var(--dark);
}

.FilePreview_viewIcon__2gV5Z {
    margin: 0 .5rem;
    width: var(--xxl);
}

.FilePreview_rotateIcon__22zdp {
    margin: 0.5rem 0.1rem;
    width: var(--xl);
    cursor: pointer;
    fill: var(--semidark);
}

.FilePreview_rotateIcon__22zdp:hover {
    fill: var(--dark);
}

.FilePreview_viewIconInactive__NJz9i {
    fill: var(--semilight);
}
.FilePreview_viewIconActive__39J10 {
    cursor: pointer;
    fill: var(--semidark);
}
.FilePreview_viewIconActive__39J10:hover {
    fill: var(--dark);
}

.FilePreview_zoomIcon__3E2ym {
    font-size: var(--xxl);
    padding: 0 .25rem;
    width: var(--s);
}
.FilePreview_zoomIconInactive__MIqjF {
    fill: var(--semilight);
}
.FilePreview_zoomIconActive__1OOuw {
    fill: var(--semidark);
    cursor: pointer;
}
.FilePreview_zoomIconActive__1OOuw:hover {
    fill: var(--dark);
}
.SaveModal_body__37FLb {
    padding: 0rem 0rem 0 0rem;
}

.SaveModal_buttons__a_7i5 {
    
    display: flex;  flex-direction: row;justify-content: space-between;
    padding: 1rem;
}

.SaveModal_button__36qDV {
    grid-area: button;
    margin-right: 1rem;
}

.SaveModal_savebutton__jSzgs {
    border-radius: 10px;
    margin-bottom: 0;
    background-color:  var(--primary);
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap;
    color: white;
}
.SaveModal_savebutton__jSzgs:hover {
    cursor: pointer;
    background-color:  var(--hover);
    color: white;
}

.SaveModal_header__3Fo2h {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.SaveModal_heading__3MuBr {
    margin: .5rem .5rem .5rem 0;
}

.SaveModal_overlay__WU9JH {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.SaveModal_wrapper__ug4G2 {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}

.SaveModal_ontop__3FHyQ{
    z-index: 400;
    position:absolute;
}


.SaveModal_x__1Tv3P {
    cursor: pointer;
    font-size: var(--xxl);
}


.SaveModal_column__3_mKP {
    min-width: 15rem;
    padding: 1rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
}

.SaveModal_bottombutton__5qEea{
    margin-left:auto;
    padding: 1rem;
}

.Detail_actionButtons__32hwg {
    display: flex; justify-content: flex-end;
}

.Detail_actionButton__E9t6- {

}

.Detail_prevNextCouple__2GNSD{
    display: flex; justify-content: flex-start;
}

.Detail_prevButton__1BzkS {
    margin-left: 1rem;
    margin-right: 5px;
}
.Detail_nextButton__xN-gS {
    margin-right: 1rem;
}

.Detail_secondaryButton__2QSN3 {
    margin-right: 1rem;
}

.Detail_container__2_uyr {
    width:100%;
    height:100%;
}
@media (min-width: 1500px) {
    .Detail_container__2_uyr {
        grid-template-columns: 60% 38%;
    }
}



.Detail_heading__3lla2 {
    justify-self: flex-start;
    margin-right: 1rem;
    max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
}




.Detail_wrap__8yoEe {
    max-width: 88vw;
}

.Detail_main__18HSF {
    flex: 1 1 auto;
}

.Detail_menuItem__2m9Sc {
    border-bottom: 3px solid transparent;
    color: var(--semidark);
    font-size: var(--s);
    font-weight: 700;
    margin: 0 1rem 1rem 0; padding-bottom: .75rem;
}
.Detail_menuItem__2m9Sc:hover, .Detail_menuItemActive__2saYr {
    border-color: var(--primary);
    color: black;
}

.Detail_navRow__3KTsk {
    display: flex; flex-wrap: wrap;
    margin-bottom: .5rem;
}

.Detail_secondaryButton__2QSN3 {
    margin-right: 1rem;
}

.Detail_topRow__yDXEk {
    display: flex; align-items: flex-start; justify-content: space-between;
}


.ProgressSpinner_spinner__2sY7E {
  color: var(--primary);
  display: block;
  margin: 0 auto;
  position: relative;
  top: 30vh;
  width: 80px;
  height: 80px;
}
.ProgressSpinner_spinner__2sY7E div {
  transform-origin: 40px 40px;
  animation: ProgressSpinner_lds-spinner__r6TSk 1.2s linear infinite;
}
.ProgressSpinner_spinner__2sY7E div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--primary);
}
.ProgressSpinner_spinner__2sY7E div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.ProgressSpinner_spinner__2sY7E div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes ProgressSpinner_lds-spinner__r6TSk {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.DocumentsTableHeader_icon__2UCEJ {
    cursor: pointer;
    fill: var(--semidark);
    height: .65rem;
    margin-left: .25rem;
}
.DocumentsTableHeader_icon__2UCEJ:hover {
    fill: var(--dark);
}

.DocumentsTableHeader_selectedtext__vAy9S {
    color: var(--purple)
}

.DocumentsTableHeader_normaltext__3IW1S {
    color: var(--semidark)
}
.DocumentsTableHeader_normaltext__3IW1S:hover {
    color: var(--dark)
}

.DocumentsTableHeader_selectedicon__20kiY {
    cursor: pointer;
    fill: var(--purple);
    height: .65rem;
    margin-left: .25rem;
}
.ExportModal_body__urK6o {
    padding: 0rem 1rem 0 1rem;
    display: flex;
   
}

.ExportModal_approvalDocuments__A0Qsk{

}

.ExportModal_withoutApprovalDocuments__2ATGP{
    color:green
}

.ExportModal_buttons__1VLqu {
    display: flex; justify-content: space-between;
    padding: 0rem;
}

.ExportModal_header__1C-aT {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.ExportModal_heading__3-UdG {
    margin: .5rem .5rem .5rem 0;
}

.ExportModal_overlay__6SjEc {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.ExportModal_wrapper__3es8a {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}
.ExportModal_large__XSU6a {
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.ExportModal_small__1qCuK {
    top: 20%;
    max-height: 60%;
}

.ExportModal_ontop__2HjJn{
    z-index: 200;
    position:absolute;
}
.ExportModal_x__2jZmU {
    cursor: pointer;
    font-size: var(--xxl);
}


.ExportModal_docColumn__1kMAa {
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
    
}

.ExportModal_columnL__2RmOS {
    flex: 50% 1;
    padding: 1rem;
    padding-bottom: 1rem;
    border-right: 1px solid var(--luight); 
}

.ExportModal_column__3YQ2- {
    flex: 50% 1;
    padding: 1rem;
    text-align: center;
}

.ExportModal_bottombutton__EcSrb{
    margin-left:auto;
    padding: 1rem;
}

.ExportModal_singlebutton__1aun9{
    padding: 0.3rem;
    width:12rem;
}
.DocumentsTableRow_captureIcon__ckdMJ {
    animation: DocumentsTableRow_rotation__BuuHm 10s infinite linear;
    fill: var(--primary);
    width: 1.25rem;
}

.DocumentsTableRow_checkboxCell__3Hz2H {
    display: flex;
    align-items: center;
}

.DocumentsTableRow_dots__M8Vae {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 900;
}

.DocumentsTableRow_focusedRow__23wmi > td {
    background-color: var(--light);
}
.DocumentsTableRow_focusedRowActive__kHXum > td {
    background-color: var(--light);
    color: var(--purple);
}

.DocumentsTableRow_hidden__1Chr9 {
    display: none;
}

.DocumentsTableRow_optionsCell__19qPi {
    cursor: pointer;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding-right: .25rem;
    position: relative;
}

.DocumentsTableRow_optionsList__2n6eU {
    position: absolute;
    background-color: white;
    box-shadow: 0 0 .75rem var(--semilight);
    padding: .5rem 0;
    position: absolute;
    top: 1.05rem;
    right: 0;
    white-space: nowrap;
    z-index: 1;
    
}

.DocumentsTableRow_optionsListReversed__2KD6T {
    background-color: white;
    box-shadow: 0 0 .75rem var(--semilight);
    padding: .5rem 0;
    position: absolute;
    bottom: 1.05rem;
    right: 0;
    white-space: nowrap;
    z-index: 1;
}

.DocumentsTableRow_optionsListIcon__1fKLM {
    fill: var(--primary);
    margin-right: .4rem;
    width: .95rem;
}

.DocumentsTableRow_exportIcon__2Z3rI {
    fill: var(--primary);
    margin-right: .4rem;
    width: .95rem;
    rotate: 270deg;
}

.DocumentsTableRow_archiveIcon__1spNb {
    fill: var(--primary);
    margin-right: .4rem;
    width: .95rem;
}

.DocumentsTableRow_optionsListCancelIcon__24Ukn {
    padding: 0 .15rem;
    width: .65rem;
}

.DocumentsTableRow_optionsListItem__25doR {
    cursor: pointer;
    display: flex; align-items: center;
    padding: .14rem .55rem;
}

.DocumentsTableRow_optionsListItem__25doR:hover {
    background-color: var(--light);
}

.DocumentsTableRow_optionsListText__NINi- {
    color: var(--dark);
}

.DocumentsTableRow_optionsTrigger__2Q3CC {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    min-height: 1.5rem;
    min-width: 1.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.DocumentsTableRow_optionsTriggerActive__1zRqH {
    background: white;
}

.DocumentsTableRow_textCell__2T35V {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: .5rem;
    text-align: right;
}
.DocumentsTableRow_by__16ycw {
    color: var(--primary);
}
.DocumentsTableRow_greyText__3t1mw {
    color: var(--dark);
}
.DocumentsTableRow_inactive__1Yhuu {
    color: var(--semidark);
}

@keyframes DocumentsTableRow_rotation__BuuHm {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.SummaryRow_textCell__3qee1 {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: .5rem;
    text-align: right;
    color: var(--semidark);
}

.SummaryRow_firstCell__3KnvJ {
    cursor: pointer;
    overflow: visible;
    white-space: normal;
    padding-top: .5rem;
    text-align: left;
    color: var(--semidark);
}


.DocumentsTable_checkboxCell__1GELa {
    display: flex;
    align-items: center;
}

.DocumentsTable_tableFull__2Db5p {
    align-self: stretch;
    
}
.DocumentsTable_summaryRow__3IvfM{
    align-self: stretch;
}
.DocumentsTable_content__9MKJ5{
    display: flex;
    flex-direction: column;
    align-self: stretch;
    height: 100%;
    overflow:auto;
    margin-top: -0.5em;
}
.DocumentsTable_tableWithoutCheckbox__1KSWm {
    align-self: stretch;
}
.DocumentsTable_tableWithoutState__h4277 {
    align-self: stretch;
}
.DocumentsTable_tableBodyScrollable__3Is06 {
    align-self: stretch;
    table-layout: fixed;
    max-height:100%;
    justify-content: start; 
}

.DocumentsTable_stretch__10HRa {
    flex: 1 1; 
    }
.FileModal_pdfContainer__12uoZ {
    flex-grow: 2;
    overflow: auto;
    width: 80vw;
   
}

.FileModal_icons__8IEcl {
    align-items:center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.FileModal_rotateIcon__1tHNJ {
    margin: 0.5rem;
    width: var(--xl);
    cursor: pointer;
    fill: var(--semidark);
}

.FileModal_rotateIcon__1tHNJ:hover {
    fill: var(--dark);
}

.FileModal_zoomIcon__3YgaJ {
    font-size: var(--xxl);
    padding: 0 .25rem;
    width: var(--s);
}
.FileModal_zoomIconInactive__3aHYn {
    fill: var(--semilight);
}
.FileModal_zoomIconActive__B1Lv3 {
    fill: var(--semidark);
    cursor: pointer;
}
.FileModal_zoomIconActive__B1Lv3:hover {
    fill: var(--dark);
}

.FileModal_onTop__33HmF{
    z-index: 450;
    position:absolute;
}
.FilterBar_button__2g1Ek {
    grid-area: button;
    margin-bottom: 0;
}
.FilterBar_filterbutton__eboYL {
    grid-area: filterbutton;
    margin-bottom: 0;
    background-color:  var(--primary);
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap;
    color: white;
}
.FilterBar_filterbutton__eboYL:hover {
    cursor: pointer;
    background-color:  var(--hover);
    color: white;
}

.FilterBar_documentType__1O5rA {
    grid-area: type;
}
.FilterBar_clientCompany__2hIAF {
    grid-area: company;
}
.FilterBar_documentState__2Eyt4 {
    grid-area: state;
}
.FilterBar_dateFrom__3CFAG {
    grid-area: from;
}
.FilterBar_dateTo__1hIyN {
    grid-area: to;
}
.FilterBar_accountingYear__1kGrM {
    grid-area: year;
}

.FilterBar_form__13HFo {
    background-color: var(--light);
    display: grid; align-items: center; grid-gap: .75rem 1rem; gap: .75rem 1rem;
    margin-bottom: 0.75rem;
    padding: .75rem;
}
.FilterBar_formWithoutCompany__2JWH6 {
    grid:
            "type state"
            "from to"
            "year button"
            / 1fr 1fr;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .FilterBar_formWithoutCompany__2JWH6 {
        grid:
            "type state year"
            "from to button"
            / 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .FilterBar_formWithoutCompany__2JWH6 {
        grid:
            "type state from to year button"
            / 2fr 2fr 2fr 2fr 2fr 1fr;
    }
}
.FilterBar_formWithoutState__NlImW {
    grid:
            "type company"
            "from to"
            "year button"
            / 1fr 1fr;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .FilterBar_formWithoutState__NlImW {
        grid:
            "type company year"
            "from to button"
            / 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .FilterBar_formWithoutState__NlImW {
        grid:
            "type company from to year button"
            / 2fr 2fr 2fr 2fr 2fr 1fr;
    }
}
.FilterBar_fullForm__2hL2J {
    z-index: 50;
    position:relative;
    grid:
            "type company state from to year filterbutton button "
            / 2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
}
/*
.fullForm {
    composes: form;
    grid:
            "type type type company company company"
            "state state state year year year"
            "from from to to button button"
            / 1fr 1fr 1fr 1fr 1fr 1fr;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .fullForm {
        grid:
            "type type type type company company company company state state state state"
            "from from from to to to year year year button button button"
            / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .fullForm {
        grid:
            "type company state from to year filterbutton button "
            / 2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
    }
}*/
.FilterBar_highlightedField__1NyRw{
    
}
.FilterBar_highlightedFieldWrap__3tf9J{
    color: var(--purple);
    
}
.FilterButtons_button__1bp4Y {
    background-color: transparent;
    border: 1px solid var(--semilight);
    color: var(--semidark);
    margin: 0;
    padding: .3rem 1rem;
}
.FilterButtons_button__1bp4Y:hover {
    background-color: var(--light);
    border-color: var(--semidark);
    color: black;
}
.FilterButtons_leftButton__3eVbL {
    border-radius: var(--radius) 0 0 var(--radius);
}
.FilterButtons_leftButtonActive__H98dp {
    background-color: var(--light);
    color: black;
}
.FilterButtons_rightButton__2JjIW {
    border-radius: 0 var(--radius) var(--radius) 0;
    border-left: none;
}
.FilterButtons_rightButtonActive__2133k {
    background-color: var(--light);
    color: black;
}

.FilterButtons_container__17M8_ {
    display: flex; align-items: center;
    grid-area: filterButtons;
    justify-self: end;
    margin-bottom: 1rem;
}
.Pagination_pageNumber__2-eKL {
    border: none;
    font-family: var(--font);
    font-size: var(--l);
    height: 1.75rem;
    width: 1.75rem;
}

.Pagination_button__1hSrE {
    background: none;
    color: var(--semidark);
    fill: var(--semidark);

}

.Pagination_button__1hSrE:disabled {
    color: var(--semilight);
    cursor: default;
    fill: var(--semilight);
}

.Pagination_button__1hSrE:not(:disabled):hover {
    color: var(--dark);
    fill: var(--dark);
}

.Pagination_current__n-ngQ {
    background: rgba(157, 59, 138, .25);
    border-radius: var(--radius);
}

.Pagination_current__n-ngQ:hover {
    cursor: default;
}

.Pagination_icon__-2LkR {
    width: var(--s);
}

.Pagination_rightIcon__1GoCg {
    margin-left: -.4rem;
}

.Pagination_pagination__duTAD {
    flex-grow: 1;
    text-align: center;

}

.Pagination_wrapper__1AH-g {
    display: flex; 
    align-items: center;
    justify-content: space-between;
}



.Pagination_paginationDiv__3zBUn {
    display: flex;
    align-items: left;
    margin-right: auto;
}

.Pagination_paginationPar__3DWsl {
    white-space: nowrap;      
    overflow: hidden;         
    text-overflow: ellipsis;  
    margin-left:0.5em;
}
.Pagination_paginationPager__GFUxT {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 1700px) {
    .Pagination_paginationPager__GFUxT {
        flex: 0 0 40%;
    }
}
@media (max-width: 1100px) {
    .Pagination_paginationPager__GFUxT {
        flex: 0 0 45%;
    }
}
@media (max-width: 728px) {
    .Pagination_paginationDiv__3zBUn {
        display: block;
    }
}

.Pagination_paginator__2Ch2B {
    margin-left: 1rem;
    width: auto;
    border-bottom: none;
    border: 1px solid var(--semilight);
    padding: 0.5rem 1rem 0.5rem 0.56rem;
}
.TemplateModal_body__1limS {
    padding: 0rem 1rem 0 1rem;
    
    display: flex;
}

.TemplateModal_approvalDocuments__2_D8Y{

}

.TemplateModal_withoutApprovalDocuments__3m8yX{
    color:green
}

.TemplateModal_buttons__34lrx {
    display: flex; justify-content: space-between;
    padding-top: 1rem;
    min-width: 15rem;
}

.TemplateModal_header__iaUnA {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.TemplateModal_heading__2-HoR {
    margin: .5rem .5rem .5rem 0;
}

.TemplateModal_overlay__2jkWh {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.TemplateModal_wrapper__xs8Qh {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}
.TemplateModal_large__UQpfE {
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.TemplateModal_small__1mrJy {
    top: 20%;
    max-height: 60%;
}

.TemplateModal_ontop__2Np7O{
    z-index: 200;
    position:absolute;
}

.TemplateModal_x__1BMb6 {
    cursor: pointer;
    font-size: var(--xxl);
}


.TemplateModal_docColumn__2GUZp {
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
    
}

.TemplateModal_columnL__1-vkp {
    flex: 50% 1;
    padding: 1rem;
    padding-bottom: 0rem;
    border-right: 1px solid var(--luight); 
}

.TemplateModal_column__2IiPV {
    flex: 50% 1;
    padding: 1rem;
    padding-bottom: 0rem;
    text-align: center;
}

.TemplateModal_bottombutton__eY5Ga{
    margin-left:auto;
    padding: 1rem;
}

.SearchBar_container__1vQvT {
    border: 1px solid var(--semilight);
    border-radius: 3px;
    display: flex; align-items: center;
    grid-area: searchBar;
    margin-bottom: 1rem;
    padding: .29rem;
}

.SearchBar_icon__1hH7D {
    fill: var(--semidark);
    height: 1rem;
    margin: 0 .4rem;
}

.SearchBar_input__1hrKm {
    border: none;
    padding: 0;
    min-width: 17rem;
}
.SearchBar_input__1hrKm:focus {
    border: none;
}
.DocumentList_sticky__1Oxv6{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

.DocumentList_bulkModifyButton__2AD8T {
    justify-self: right;
    grid-column: 1;
    margin-right: 0.2em
}

.DocumentList_exportButton__3vMVT {
    justify-self: right;
    grid-column: 2;
    margin-right: 0.2em
}


.DocumentList_exportButtonArchive__1tuDR {
    justify-self: right;
    grid-column: 3;
}

.DocumentList_captureButton__2vwWR {
    justify-self: right;
    grid-column: 3;
    margin-right: 0.2em
}

.DocumentList_actionButton__TvzpE {
    justify-self: right;
    grid-column: 4;
}


.DocumentList_buttons__Lsvz8 {
    display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    }

.DocumentList_buttonsArchive__2fPAk {
    display: grid; grid-template-columns: 1fr 1fr 1fr;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    }

.DocumentList_footer__WkXYF {
    width: 100%;
}

.DocumentList_footerArchive__2XDmB {
    display: grid; grid-template-columns: 3fr  1fr 1fr ;
    align-items: right;
    margin: .5rem 0;
    width: 100%;
}


.DocumentList_footerNarrow__1lEp6 {
    display: flex;
    flex-direction: column;
}
.DocumentList_buttonsNarrow__1TMHD {
    display: grid; grid-template-columns:  1fr 1fr 1fr ;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    grid-column: 2;
}
.DocumentList_buttonsNarrowArchive__2wOU5 {
    display: grid; grid-template-columns: 1fr 1fr 1fr ;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    grid-column: 2;
}
.DocumentList_footerNarrowArchive__36C-Y {
    display: grid;
    grid-template-columns:  1fr 1fr;
    grid-template-rows: auto auto;
    align-items: right;
    margin: .5rem 0;
    width: 100%;
}


.DocumentList_topRowButtons__IsMeW {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr repeat(4, auto);
    justify-content: flex-start;
    grid-gap: 0em;
    gap: 0em;
}



.DocumentList_leftButtons__2AzeY {
    grid-column: 1; 
    display: grid;
    grid-template-columns: auto auto auto;  
    grid-gap:5px;  
    gap:5px;
    width :100%;
    justify-content: flex-start;
  }

  
.DocumentList_rightButtons__2L-Wi {
    display: flex;
  justify-content: flex-end;
  grid-gap: 5px;
  gap: 5px; 
  grid-column: 3 / span 4; 
  }

  .DocumentList_leftButtons__2AzeY button {
    height: 2.0em; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    padding: 0 0.5em; 
  }
  .DocumentList_rightButtons__2L-Wi button {
    height: 2.0em; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    padding: 0 0.5em; 
  }

  .DocumentList_customButton__22IF_ {
    height: 1em !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 0.5em !important;
  }
  

.DocumentList_heading__8bgJm {
    grid-area: heading;
}

.DocumentList_wrap__ZHUCD{
   max-width: 90vw;
}

.DocumentList_main__1e60y {
    /* display: grid; align-items: start; gap: 1rem; grid-template-columns: auto; */
}
/*
@media (min-width: 1024px) {
    .main {
        grid-template-columns: auto 22.25vw;
    }
}
*/
.DocumentList_tableContainer__3kCpO {
    display: flex; align-items: flex-end; flex-direction: column; height:72vh
}

.DocumentList_topRow__1-Frw {
    margin-bottom: -0.5em;
    display: grid; align-items: start; grid-gap: 0 1rem; gap: 0 1rem;
    grid:
        "heading filterButtons"
        "searchBar searchBar"
        / 1fr 1fr;
}
@media (min-width: 768px) {
    .DocumentList_topRow__1-Frw {
        grid:
            "heading searchBar filterButtons"
            / 1fr 1fr 1fr;
    }
}
.Footer_footer__12KgG {
    background: var(--light);
    box-sizing: border-box;
    padding: .75rem 1.5rem;
    width: 100%;
}

.Footer_pipe__2xsYI {
    margin: 0 .75rem;
}

.Footer_registered__3y0bg {
    margin-right: .25rem;
}

.Footer_text__uh_V_ {
    color: var(--dark);
    font-size: var(--xs);
    text-align: right;
}
.Avatar_circle__3NddV {
    align-items: center;
    background-color: var(--purple);
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0.4;
    width: 100%;
}
.Avatar_circle__3NddV:hover {
    opacity: 1;
}

.Avatar_initials__3zKDD {
    color: white;
    font-weight: 900;
}

.Avatar_profilePicture__3_n0b {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.Nav_avatar__3ftke {
    flex-shrink: 0;
    grid-area: avatar;
    height: 2.25rem;
    width: 2.25rem;
}

.Nav_bottom__1_Pqm {
    margin: 0 0 .5rem 1rem;
}

.Nav_container__2oGn4 {
    background-color: var(--light);
    flex-shrink: 0;
    width: 10rem;
    position: -webkit-sticky;
    position: sticky; top: 0;
}

.Nav_logo__1tYC8 {
    margin-bottom: 1.5rem;
    max-width: 1.5rem;
}

.Nav_logOut__aEm-9 {
    cursor: pointer;
    color: var(--purple);
    font-size: var(--xs);
    grid-area: logout;
    margin-top: .0rem;
    padding: 0;
    border:0;
    background:none;
    text-align: left;
}
.Nav_logOut__aEm-9:hover {
    color: var(--hover);
}

.Nav_menu__1SgmZ {
    display: flex; flex-direction: column;
}

.Nav_menuItem__1Uhr2 {
    color: var(--dark);
    font-size: var(--m);
    line-height: 2rem;
}
.Nav_menuItem__1Uhr2:hover, .Nav_menuItemActive__RZ7ES {
    color: var(--primary) !important;
}

.Nav_sticky__2NUFn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.Nav_top__1miYd {
    margin: 1.5rem 0 0 2rem;
}

.Nav_userCompany__3JT57 {
    color: var(--dark);
    font-size: var(--xs);
    grid-area: company;
    overflow: hidden; text-overflow: ellipsis;
}

.Nav_userContainer__2Neg0 {
    display: grid;
    grid:
        "avatar name"
        "avatar company"
        ". logout"
        / auto 1fr;
    grid-gap: .25rem;
    gap: .25rem;
    margin: 1rem .1rem .25rem 0;
}

.Nav_userFullName__2ry1L {
    color: black;
    font-size: var(--s);
    grid-area: name;
    overflow: hidden; text-overflow: ellipsis;
}

.Nav_choice__2jTdv {
    display: inline-flex;
    margin: 4px 0;
}

.Nav_radioContainer__3klFD {
    font-size: medium;
}

.Nav_radioContainer__3klFD input[type=radio]{
    margin: 0 0 0;
    vertical-align: middle;
    transform: translate(-2px,0px);
    box-shadow: inset 0 0 0 .2rem white;
    height: 1rem;
    width: 1rem;
    padding: 0;
    flex-shrink: 0;
    /*transform: scale(0.5, 0.5);*/
}

.Nav_radioContainer__3klFD label{
    /*flex-shrink: 1;*/
    /*text-transform: capitalize;*/
    font-size:var(--m);
    font-weight: normal;
    color: var(--dark);
    /*overflow: hidden;*/
    text-overflow: ellipsis;
}

.Nav_radioContainer__3klFD label:hover{
    color: var(--primary) !important;
}
.ProfileFormBody_row__1UVC7 {
    display: flex; justify-content: flex-end;
    margin: .5rem 0;
}
.User_avatarContainer__3rwaH {
    flex-shrink: 0;
    font-size: 1.75rem;
    height: 4rem;
    margin-right: 1rem;
    width: 4rem;
}

.User_avatarUpload__1oxLU {
    cursor: pointer;
    display: flex; align-items: center;
    grid-row: 1;
    margin-bottom: 1rem;
}

.User_avatarUploadText__1lS7I {
    color: var(--primary);
    font-weight: 300;
}
.User_avatarUploadText__1lS7I:hover {
    color: var(--hover);
}

.User_editIcon__23A2v {
    fill: var(--primary);
    height: .9rem;
    margin-right: .25rem;
}
.User_editIcon__23A2v:hover {
    fill: var(--hover);
}

.User_form__1YY_t {
    margin-bottom: 1.5rem;
}
.ClientCompanyExport_formContainer__2CavX {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.ClientCompanyExport_field__3d_Bm{
    
}
.ClientCompanyExport_button__UT1UG {
   padding-left: 2em;
}
.Profile_container__3jU4u {
    max-width: var(--form-max-width);
}

.Profile_input__27cPU {
    border: none;
}
.Profile_input__27cPU:disabled {
    background-color: white;
}
.Profile_firstNameInput__3yp1l {
    flex-basis: 50%;
}

.Profile_lastNameInput__3QoDz {
    align-items: center;
    display: flex;
    flex-basis: 50%
}



.Upload_cancelIcon__3rORY {
    cursor: pointer;
    fill: var(--primary);
    height: .5rem;
}

.Upload_checkIcon__3qhCR {
    fill: var(--green);
    height: .75rem;
}

.Upload_dropzone__2fgnI {
    border: 2px dashed var(--light);
}

.Upload_dropzoneBox__1w0_W {
    flex: 1 1;
}

.Upload_dropzoneCaption__354TQ {
    color: var(--dark);
    font-size: var(--m);
    font-weight: 300;
    margin: 0 1rem;
}

.Upload_fileName__1axUE {
    font-size: var(--s);
    flex-basis: 65%;
}
.Upload_fileNameDone__2c6AC {
    color: black;
}
.Upload_fileNameDefault__1Rxs- {
    color: var(--dark);
}

.Upload_fileSize__sf-wW {
    color: var(--dark);
    font-size: var(--s);
    flex-basis: 15%;
}

.Upload_icon__3YFpQ {
    height: 1.25rem;
    text-align: right;
    width: 1rem;
}
.Upload_fileIcon__4XBgn {
    fill: var(--dark);
}
.Upload_editIcon__2JKWp {
    fill: var(--dark);
}
.Upload_errorIcon__3W7b9 {
    color: var(--red);
    font-size: var(--xl);
    font-weight: bold;
}

.Upload_errorMessage__1DIn5 {
    color: var(--red);
    font-size: var(--s);
}

.Upload_hint__Su7rb {
    background-color: var(--light);
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
}

.Upload_hintSymbol__10DtG {
    color: var(--primary);
    font-weight: 700;
    margin: .45rem .25rem .55rem 0;
}

.Upload_hintText__3nueb {
    color: var(--semidark);
    font-size: .8rem;
    margin: .5rem 0;
}

.Upload_inputLabel__121tu {
    cursor: pointer;
    display: flex; flex-direction: column; justify-content: center;
    min-height: 50vh;
    font-family: inherit;
    text-align: center;
}

.Upload_previewContainer__27gu7 {
    display: flex;
}

.Upload_progressBox__1jE6H {
    flex: 1 1;
}

.Upload_progressContainer__3eK15 {
    flex: 1 1;
    margin: 0 0 2rem .5rem;
}

.Upload_progressPlaceholder__2BMtm {
    height: .25rem;
}

.Upload_progressRow__38ITS {
    display: flex;
    justify-content: space-between;
    margin-top: .25rem;
}

.Upload_uploadIcon__1STow {
    fill: var(--purple);
    height: 5rem;
    margin-bottom: 2rem;
    opacity: .4;
}

.Upload_wrapper__2yWEb {
    display: flex; flex-direction: column;
}
.Wrapper_container__4Kf0G {
    display: flex;
    min-height: 100vh;
}

.Wrapper_content__19CB2 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Wrapper_main__3P8KN {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 1rem;
}
